window.addEventListener('load', ()=> {
    popup();
    animation();
});

function popup() {
    const body = document.querySelector('body');
    const button = body.querySelector('.home-hero .JSPopupOpen');
    const popup = body.querySelector('.JSHeroPopup');
    const close = popup.querySelector('.close-btn');

    button.addEventListener('click', ()=> {
        body.classList.add('no-scrole');
        popup.style.display = 'flex';
    });

    close.addEventListener('click', ()=> {
        body.classList.remove('no-scrole');
        popup.style.display = 'none';
    });
}

function animation() {
    const hero = document.querySelector('.home-hero');
    const bgHomeHero = hero.querySelector('.bg.home-hero');
    const image = hero.querySelector('.image-wrapper.home-hero');
    
    image.removeAttribute('style');
    bgHomeHero.removeAttribute('style');
}